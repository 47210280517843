import DashBoard     from '@/components/DashBoard/DashBoard.vue'
import HomeCard      from './components/HomeCard.vue'
import HomePrincipal from './components/HomePrincipal.vue'

import { 
    createNamespacedHelpers 
} from 'vuex'
const {
    mapState,
    mapActions
} = createNamespacedHelpers("home")
export default {    
    name : "Home",
    props : {
        par : Number
    },
    components : {
        DashBoard,
        HomeCard,
        HomePrincipal
    },
    computed : {
        ...mapState( ["currentModule","component"] ),    
        componentLoader () {                 
            if( this.component == "" )             
                return null
            else             
                return () => import(`@/views/${this.component}.vue`)
        },
        currentModuleComponent : function(){
            return 'home-' + this.currentModule
        }
    },
    methods : {
        ...mapActions(["setCurrentModule"])
    },
    created(){
        this.setCurrentModule("principal")
    } 
}
